import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import ComingSoon from "react-coming-soon";

import Layout from '../components/Layout'
import contactData from './../data/contact'

class Contact extends React.Component {
//   render() {
//     const siteTitle = get(this, 'props.data.site.siteMetadata.title')
//     const siteDescription = get(
//       this,
//       'props.data.site.siteMetadata.description'
//     )

//     return (
//       <Layout>
//         <Helmet
//           htmlAttributes={{ lang: 'en' }}
//           meta={[{ name: 'description', content: siteDescription }]}
//           title={`Contact | ${siteTitle}`}
//         />

//         {/* <h2>Contact</h2>
//         <br /> */}

//         {/* {contactData.map(item => {
//             return (
//               <p key={item.type}>
//                 <b>{item.type}: </b>
//                 <a style={{}} href={item.link} target="_new">
//                   {item.link}
//                 </a>
//               </p>
//             )
//         })} */}

//         {Object.keys(contactData).map(type => {
//           if (contactData[type]!=contactData['Email']) {
//             return (
//               <p key={type}>
//                 <b>{type}: </b>
//                 <a style={{}} href={contactData[type]} target="_new">
//                   {contactData[type]}
//                 </a>
//               </p>
//             )
//           }
//           else{
//             return(
//               <p key={type}>
//                 <b>{type}: </b>
//                 <a style={{}} href={"mailto:"+contactData[type]} target="_new">
//                   {contactData[type]}
//                 </a>
//               </p>
//             )
//           }
//         })}
//       </Layout>
//     )
//   }
// }


render() {
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')
  const siteDescription = get(
    this,
    'props.data.site.siteMetadata.description'
  )

  return (
    // <h1>Hi</h1>
      <ComingSoon
            title="Mohammad's Portfolio"
            subtitle="Coming Soon"
            image="https://react-coming-soon.maksv.me/default-image.jpeg"
            bgColor="#fff"
            textColor="#000"
            illustration="development"
          />
  );
}
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
